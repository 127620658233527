import React, { FC } from 'react'
import { styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { Text } from '@moonpig/launchpad-components'
import { DepartmentType } from '../departmentMap'

type DepartmentTabsProps = {
  selectedDepartment: DepartmentType
  setSelectedDepartment: (department: DepartmentType) => void
  department: DepartmentType
} & React.HTMLProps<HTMLElement>

const Tab = styled.button<{
  active: boolean
}>`
  ${s({
    paddingX: 4,
    paddingY: 6,
    fontSize: 14,
    fontWeight: 400,
    border: '2px solid',
    borderRadius: 5,
    height: '40px',
    minWidth: '55px',
  })}

  ${({ active }) =>
    s({
      color: active ? 'colorPrimary02' : 'colorInteractionControl',
      bgcolor: active ? 'colorBackground03' : 'colorPrimary03',
      borderColor: active ? 'colorBackground03' : 'colorBorder03',
    })}

  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const DepartmentTab: FC<DepartmentTabsProps> = ({
  selectedDepartment,
  setSelectedDepartment,
  department,
}) => {
  const { name, label } = department
  const isActive = name === selectedDepartment.name

  return (
    <Tab
      data-testid={`department-tab-${name}`}
      data-active={isActive ? 'true' : 'false'}
      active={isActive}
      onClick={e => {
        e.preventDefault()
        setSelectedDepartment(department)
      }}
    >
      <Text typography="typeBodyLabel" fontWeight={700} fontSize={16}>
        {label}
      </Text>
    </Tab>
  )
}
