import React, { FC, useEffect } from 'react'
import { breakpoint, styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { Box, Text } from '@moonpig/launchpad-components'
import { DepartmentsEnum } from '@moonpig/web-core-types-graphql'
import { useCoreLocaleText } from '@moonpig/web-core-locale-text'
import { Suggestions } from './Suggestions/Suggestions'
import { SuggestionType } from './Suggestions/Suggestion'
import { NavigationalSuggestionType } from './NavigationalSuggestions/NavigationalSuggestion'
import { NavigationalSuggestions } from './NavigationalSuggestions/NavigationalSuggestions'
import {
  SearchItemType,
  SuggestionItemType,
  UrlSuggestionItemType,
} from './types'
import { useLocaleText } from '../locale'
import {
  MAX_NAVIGATIONAL_SUGGESTIONS,
  MAX_SUGGESTIONS,
  SEARCH_BAR_HEIGHT_PX,
} from './constants'
import { departmentMap, DepartmentType } from './departmentMap'
import { DepartmentTabs } from './DepartmentTabs/DepartmentTabs'

type AutocompleteSearchBoxProps = {
  isOpen: boolean
  suggestions: SuggestionType[]
  navigationalSuggestions: NavigationalSuggestionType[]
  inputValue: string | null
  getItemProps: (props: {
    itemIndex: number
    item: SuggestionItemType | UrlSuggestionItemType | SearchItemType
  }) => Record<string, unknown>
  getMenuProps: () => Record<string, unknown>
  highlightedIndex: number | null
  value: string
  onSuggestionsVisible: () => void
  maxSuggestions?: number
  maxNavigationalSuggestions?: number
  navigationalHeaderText?: string
  suggestionsLoading?: boolean
  searchTrendingSearchesEnabled?: boolean
  departmentSuggestionsEnabled?: boolean
  department?: string
  selectedDepartment: DepartmentType
  setActiveDepartment: (department: DepartmentType) => void
} & React.HTMLProps<HTMLElement>

const StyledContainer = styled.div.attrs(({ isOpen }: { isOpen: boolean }) => ({
  className: !isOpen && 'hidden',
}))<{ isOpen: boolean }>`
  position: relative;
  top: ${SEARCH_BAR_HEIGHT_PX};
  z-index: 3;
  overflow-y: auto;
  max-height: 100vh;
  ${s({
    pb: 4,
    bgcolor: 'colorBackground01',
  })};

  &.hidden {
    display: none;
  }

  ${breakpoint('md')} {
    max-height: 75vh;
    overflow-y: auto;
    border-radius: 0px 0px 8px 8px;

    &::-webkit-scrollbar {
      width: 12px;
    }

    &::-webkit-scrollbar-track {
      ${s({
        bgcolor: 'colorBlack00',
      })}
      border-radius: 8px;
    }

    &::-webkit-scrollbar-thumb {
      ${s({
        bgcolor: 'colorBlack20',
      })}
      border-radius: 85px;
    }
  }
`

const StyledDepartmentHeader = styled(Text)`
  ${s({
    fontSize: '12px',
    lineHeight: '24px',
    fontWeight: 'bold',
    color: 'colorTextLabel',
    pl: 5,
    mb: 0,
  })};
`

const StyledSection = styled(Box)`
  ${s({
    mb: 6,
    px: 3,
    borderBottom: 1,
    borderColor: 'colorBorder03',
  })};
`
export const AutocompleteSearchBox: FC<
  React.PropsWithChildren<AutocompleteSearchBoxProps>
> = ({
  isOpen,
  suggestions,
  navigationalSuggestions,
  inputValue,
  getItemProps,
  getMenuProps,
  highlightedIndex,
  value,
  onSuggestionsVisible,
  maxNavigationalSuggestions = MAX_NAVIGATIONAL_SUGGESTIONS,
  navigationalHeaderText = '',
  suggestionsLoading,
  searchTrendingSearchesEnabled,
  departmentSuggestionsEnabled,
  department,
  selectedDepartment,
  setActiveDepartment,
}) => {
  const localiseNavText = useLocaleText()
  const localiseCoreText = useCoreLocaleText()

  const suggestionsVisible = isOpen && inputValue

  const showNavigationalSuggestions =
    navigationalSuggestions.length && suggestions.length < MAX_SUGGESTIONS - 1

  useEffect(() => {
    if (suggestionsVisible) {
      onSuggestionsVisible()
    }
  }, [onSuggestionsVisible, suggestionsVisible])

  const MAX_CARD_SUGGESTIONS = departmentSuggestionsEnabled ? 5 : 10
  const MAX_GIFT_SUGGESTIONS = departmentSuggestionsEnabled ? 3 : 10
  const MAX_FLOWERS_AND_PLANTS_SUGGESTIONS = departmentSuggestionsEnabled
    ? 3
    : 10

  const cardSuggestions = suggestions.filter(
    suggestion => suggestion.department.enum === DepartmentsEnum.ALL_CARDS,
  )
  const giftSuggestions = suggestions.filter(
    suggestion => suggestion.department.enum === DepartmentsEnum.ALL_GIFTS,
  )
  const flowersAndPlantsSuggestions = suggestions.filter(
    suggestion =>
      suggestion.department.enum === DepartmentsEnum.ALL_FLOWERS_AND_PLANTS,
  )

  const shouldShowSuggestions = (targetDepartment: string) => {
    return (
      (departmentSuggestionsEnabled &&
        ['all', targetDepartment].includes(selectedDepartment.name)) ||
      (searchTrendingSearchesEnabled && department === targetDepartment)
    )
  }

  const shouldShowCardsSuggestions = shouldShowSuggestions(
    departmentMap.Cards.name,
  )
  const shouldShowGiftsSuggestions = shouldShowSuggestions(
    departmentMap.Gifts.name,
  )
  const shouldShowFlowersAndPlantsSuggestions = shouldShowSuggestions(
    departmentMap.FlowersAndPlants.name,
  )

  const shouldRenderDepartmentHeading = departmentSuggestionsEnabled

  const calculateStartIndex = (
    suggestionsLength: number,
    maxSuggestions: number,
    searchInputValue: string | null,
  ): number =>
    Math.min(maxSuggestions, suggestionsLength) +
    (searchInputValue && searchInputValue.length > 1 ? 1 : 0)

  const giftsStartIndex = calculateStartIndex(
    cardSuggestions.length,
    MAX_CARD_SUGGESTIONS,
    inputValue,
  )

  const flowerAndPlantsStartIndex =
    giftsStartIndex +
    calculateStartIndex(
      giftSuggestions.length,
      MAX_GIFT_SUGGESTIONS,
      inputValue,
    )

  return (
    <StyledContainer isOpen={suggestionsVisible}>
      {isOpen && suggestionsVisible ? (
        <div data-testid="lp-nav-autocomplete-searchbox">
          <DepartmentTabs
            selectedDepartment={selectedDepartment}
            setSelectedDepartment={setActiveDepartment}
          />
          {shouldShowCardsSuggestions && (
            <StyledSection>
              {shouldRenderDepartmentHeading && (
                <StyledDepartmentHeader as="h4">
                  {localiseCoreText('common.cards').toUpperCase()}
                </StyledDepartmentHeader>
              )}

              <Suggestions
                highlightedIndex={highlightedIndex}
                value={value}
                department={departmentMap.Cards}
                suggestions={cardSuggestions}
                maxSuggestions={MAX_CARD_SUGGESTIONS}
                getItemProps={getItemProps}
                getMenuProps={getMenuProps}
                suggestionsLoading={suggestionsLoading}
                departmentSuggestionsEnabled={departmentSuggestionsEnabled}
              />
            </StyledSection>
          )}

          {shouldShowGiftsSuggestions && (
            <StyledSection>
              {shouldRenderDepartmentHeading && (
                <StyledDepartmentHeader as="h4">
                  {localiseCoreText('common.gifts').toUpperCase()}
                </StyledDepartmentHeader>
              )}

              <Suggestions
                highlightedIndex={highlightedIndex}
                value={value}
                suggestions={giftSuggestions}
                department={departmentMap.Gifts}
                maxSuggestions={MAX_GIFT_SUGGESTIONS}
                getItemProps={getItemProps}
                getMenuProps={getMenuProps}
                startIndex={giftsStartIndex}
                suggestionsLoading={suggestionsLoading}
                departmentSuggestionsEnabled={departmentSuggestionsEnabled}
              />
            </StyledSection>
          )}

          {shouldShowFlowersAndPlantsSuggestions && (
            <StyledSection>
              {shouldRenderDepartmentHeading && (
                <StyledDepartmentHeader as="h4">
                  {localiseCoreText('common.flowers_and_plants').toUpperCase()}
                </StyledDepartmentHeader>
              )}

              <Suggestions
                highlightedIndex={highlightedIndex}
                value={value}
                suggestions={flowersAndPlantsSuggestions}
                department={departmentMap.FlowersAndPlants}
                maxSuggestions={MAX_FLOWERS_AND_PLANTS_SUGGESTIONS}
                getItemProps={getItemProps}
                getMenuProps={getMenuProps}
                startIndex={flowerAndPlantsStartIndex}
                suggestionsLoading={suggestionsLoading}
                departmentSuggestionsEnabled={departmentSuggestionsEnabled}
              />
            </StyledSection>
          )}

          {showNavigationalSuggestions ? (
            <StyledSection>
              <StyledDepartmentHeader as="h4">
                {navigationalHeaderText === ''
                  ? localiseNavText(
                      'search.navigational_suggestions_header',
                    ).toUpperCase()
                  : navigationalHeaderText.toUpperCase()}
              </StyledDepartmentHeader>
              <NavigationalSuggestions
                navigationalSuggestions={navigationalSuggestions}
                maxNavigationalSuggestions={maxNavigationalSuggestions}
                getItemProps={getItemProps}
                getMenuProps={getMenuProps}
              />
            </StyledSection>
          ) : null}
        </div>
      ) : null}
    </StyledContainer>
  )
}
