import React, { FC } from 'react'
import { styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { useStoreId } from '@moonpig/web-core-stores'
import { Divider } from '@moonpig/launchpad-components'
import { DepartmentTab } from './Tab'
import { departmentMap, DepartmentType } from '../departmentMap'

type DepartmentTabsProps = {
  selectedDepartment: DepartmentType
  setSelectedDepartment: (department: DepartmentType) => void
} & React.HTMLProps<HTMLElement>

const Tabs = styled.div`
  display: flex;
  ${s({
    height: '56px',
    marginLeft: 6,
    paddingY: 4,
    gap: 4,
  })}
`

const DepartmentTabsContainer = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;
  ${s({
    bgcolor: 'colorBackground01',
  })}
`

const StyledDivider = styled(Divider)`
  ${s({
    marginTop: 0,
    marginBottom: 6,
  })}
`

export const DepartmentTabs: FC<DepartmentTabsProps> = ({
  selectedDepartment,
  setSelectedDepartment,
}) => {
  const departments = Object.values(departmentMap)
  const storeId = useStoreId()

  return (
    <DepartmentTabsContainer data-testid="department-tabs">
      <Tabs>
        {departments.map(department => {
          if (department.storeAllowList && department.storeAllowList[storeId]) {
            return (
              <DepartmentTab
                key={department.title}
                selectedDepartment={selectedDepartment}
                setSelectedDepartment={setSelectedDepartment}
                department={department}
              />
            )
          }
          return null
        })}
      </Tabs>
      <StyledDivider />
    </DepartmentTabsContainer>
  )
}
