import { DepartmentsEnum } from '@moonpig/web-core-types-graphql'

export type DepartmentType = {
  name: string
  title: string
  enum: DepartmentsEnum
  label: string
  storeAllowList?: {
    [key: string]: boolean
  }
}

export const departmentMap: {
  [key: string]: DepartmentType
} = {
  All: {
    name: 'all',
    title: 'All',
    label: 'All',
    enum: DepartmentsEnum.ALL_CARDS,
    storeAllowList: {
      uk: true,
      us: true,
      nl: true,
      ie: true,
      au: true,
    },
  },
  Cards: {
    name: 'all_cards',
    title: 'Cards',
    label: 'Cards',
    enum: DepartmentsEnum.ALL_CARDS,
    storeAllowList: {
      uk: true,
      us: true,
      nl: true,
      ie: true,
      au: true,
    },
  },
  Gifts: {
    name: 'all_gifts',
    title: 'Gifts',
    label: 'Gifts',
    enum: DepartmentsEnum.ALL_GIFTS,
    storeAllowList: {
      uk: true,
      us: false,
      nl: true,
      ie: true,
      au: true,
    },
  },
  FlowersAndPlants: {
    name: 'all_flowers_and_plants',
    title: 'Flowers & Plants',
    enum: DepartmentsEnum.ALL_FLOWERS_AND_PLANTS,
    label: 'Flowers',
    storeAllowList: {
      uk: true,
      us: false,
      nl: true,
      ie: true,
      au: true,
    },
  },
}
