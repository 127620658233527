import { GetExperiment } from '@moonpig/web-core-eppo/server'
import { graphQlExperimentsWhitelist } from './graphQlExperimentsWhitelist'

type SitespectExperiments = { [key: string]: string }

export const buildExperimentsHeader = (
  sitespectExperiments: SitespectExperiments,
  getEppoExperimentString: GetExperiment<string>,
  currentDate: Date = new Date(),
): string => {
  const activeExperiments = graphQlExperimentsWhitelist
    .filter(experiment =>
      experiment.expiry ? experiment.expiry >= currentDate : true,
    )
    .map(({ key }) => key)

  const headerParts = activeExperiments
    .map(key => {
      if (sitespectExperiments[key]) {
        return `${key}=${sitespectExperiments[key]}`
      }

      const variant = getEppoExperimentString(key, {
        fallback: 'fallback',
      })
      return `${key}=${variant}`
    })
    .filter(Boolean)

  return headerParts.sort().join(';')
}
