type ABTestingServer = {
  getVariation(campaignName: string): string
  getAllCampaigns(): { [key: string]: string }
}

const campaigns: { [key: string]: string } = {
  'attach-basket-xsell-algorithm': 'Not bucketed',
  'show-gift-vouchers': 'Not bucketed',
  'site-xsell-algorithm': 'Not bucketed',
  'customer-credit-nav': 'Not bucketed',
  'browse-login-tooltip': 'Not bucketed',
  'attach-product-video': 'Not bucketed',
  'subscriptions-sign-up-component': 'VariantB',
  'loyalty-plus-in-basket-redesign-v2': 'Not bucketed',
  'customer-order-conf-redesign': 'Not bucketed',
  'mnpg-price-trial': 'Not bucketed',
  'customer-google-one-tap': 'Not bucketed',
  'explore-editable-card-content': 'Not bucketed',
  'explore-show-klarna': 'Not bucketed',
}
export const getABTestingServer = (): ABTestingServer => {
  return {
    getVariation: (campaignName: string) => campaigns[campaignName],
    getAllCampaigns: () => campaigns,
  }
}
